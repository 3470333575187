"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Bugfender = void 0;
const common_1 = require("@bugfender/common");
const issue_1 = require("./types/issue");
const main_provider_1 = require("./providers/main.provider");
const utils_1 = require("./utils");
class Bugfender {
    constructor(preInitProvider, sdkUserAgent, version) {
        this.preInitProvider = preInitProvider;
        this.sdkUserAgent = sdkUserAgent;
        this.version = version;
        this.LogLevel = common_1.LogLevel;
        this.initDeferred = (0, utils_1.createDeferred)();
        this.commands = this.preInitProvider.provideCommandsQueue();
    }
    init(options) {
        return __awaiter(this, void 0, void 0, function* () {
            const operationModes = this.preInitProvider.provideOperationModes();
            const sdkOptionsValidator = this.preInitProvider.provideSDKOptionsValidator();
            this.options = sdkOptionsValidator.init(options);
            this.provider = new main_provider_1.MainProvider(this, this.options, this.preInitProvider, this.sdkUserAgent, this.version);
            this.processor = yield this.provider.getStorageProcessor();
            if (this.options.debug) {
                const apiFeatures = this.preInitProvider.provideAPIFeatureDetection();
                const logger = this.provider.getLogger(Bugfender.name);
                logger.log('DEBUG', {
                    debug: this.options.debug,
                    operation: {
                        mode: yield operationModes.getMode(),
                        isMultiTab: yield operationModes.isMultiTab(),
                        isMultiThread: yield operationModes.isMultiThread(),
                    },
                    features: {
                        broadcastChannel: yield apiFeatures.isBroadcastChannelSupported(),
                        indexedDB: yield apiFeatures.isIndexedDBSupported(),
                        localStorage: apiFeatures.isLocalStorageSupported(),
                        sessionStorage: apiFeatures.isSessionStorageSupported(),
                        worker: apiFeatures.isWorkerSupported(),
                    }
                });
            }
            const resetStorage = yield this.provider.getResetStorage();
            yield resetStorage.resetIfAppChanged(this.options.appID);
            const storage = yield this.provider.getStorageWriterHub();
            storage.init(this.options);
            if (this.options.logBrowserEvents) {
                this.provider.getRegisterBrowserEventsHandler().init();
            }
            if (this.options.logUIEvents) {
                this.provider.getRegisterUIEventsHandler().init();
            }
            if (this.options.registerErrorHandler) {
                this.provider.getRegisterErrorHandler().init(this);
            }
            if (this.options.overrideConsoleMethods) {
                this.provider.getOverrideConsoleMethods().init(this.options.printToConsole);
            }
            this.provider.getPrintToConsole().init(this.options.printToConsole);
            window.addEventListener('beforeunload', () => __awaiter(this, void 0, void 0, function* () {
                this.processor.run();
            }));
            this.commands.init(storage);
            const browser = this.preInitProvider.provideBrowser();
            this.setDeviceKey('$browser', browser.getName());
            this.initDeferred.resolve();
        });
    }
    forceSendOnce() {
        this.commands.forceSendOnce();
    }
    getDeviceURL() {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.initDeferred.promise;
            const device = yield this.provider.getDevice();
            return `${this.options.baseURL}/intent/${this.options.appID}/device/${device.getUDID()}`;
        });
    }
    getSessionURL() {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.initDeferred.promise;
            const session = yield this.provider.getCurrentSession();
            return `${this.options.baseURL}/intent/${this.options.appID}/session/${session.getUUID()}`;
        });
    }
    getUserFeedback(options) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.preInitProvider.provideUserFeedback().show(options);
        });
    }
    log(...parameters) {
        this.commands.addLogMessage(common_1.LogLevel.Debug, [...parameters]);
    }
    warn(...parameters) {
        this.commands.addLogMessage(common_1.LogLevel.Warning, [...parameters]);
    }
    error(...parameters) {
        this.commands.addLogMessage(common_1.LogLevel.Error, [...parameters]);
    }
    trace(...parameters) {
        this.commands.addLogMessage(common_1.LogLevel.Trace, [...parameters]);
    }
    info(...parameters) {
        this.commands.addLogMessage(common_1.LogLevel.Info, [...parameters]);
    }
    fatal(...parameters) {
        this.commands.addLogMessage(common_1.LogLevel.Fatal, [...parameters]);
    }
    removeDeviceKey(key) {
        this.commands.setDeviceKey(key, null);
    }
    sendLog(log) {
        this.commands.addLogEntry(log);
    }
    sendIssue(title, text) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.commands.addIssue(issue_1.IssueType.Issue, title, text);
        });
    }
    sendCrash(title, text) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.commands.addIssue(issue_1.IssueType.Crash, title, text);
        });
    }
    sendUserFeedback(title, text) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.commands.addIssue(issue_1.IssueType.Feedback, title, text);
        });
    }
    setDeviceKey(key, value) {
        this.commands.setDeviceKey(key, value);
    }
    setForceEnabled(state) {
        this.initDeferred.promise.then(() => {
            this.provider.getStorageGuard().setDeviceForceEnabled(state);
        });
    }
}
exports.Bugfender = Bugfender;
