"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LogFactory = void 0;
const common_1 = require("@bugfender/common");
class LogFactory {
    createFromDeviceKey(key, value, callInfo, time) {
        return this.createFromEntry({
            tag: 'bf_key_value',
            text: `Set device data "${key}"="${value}"`,
        }, callInfo, time);
    }
    createFromEntry(log, callInfo, time) {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
        return {
            x: time.iso,
            t: (_a = log.text) !== null && _a !== void 0 ? _a : '',
            m: (_c = (_b = log.method) !== null && _b !== void 0 ? _b : callInfo.method) !== null && _c !== void 0 ? _c : '',
            at: time.epoch,
            tg: (_d = log.tag) !== null && _d !== void 0 ? _d : '',
            f: (_f = (_e = log.file) !== null && _e !== void 0 ? _e : callInfo.file) !== null && _f !== void 0 ? _f : '',
            l: (_h = (_g = log.line) !== null && _g !== void 0 ? _g : callInfo.line) !== null && _h !== void 0 ? _h : 0,
            ll: (_j = log.level) !== null && _j !== void 0 ? _j : common_1.LogLevel.Debug,
            u: (_k = log.url) !== null && _k !== void 0 ? _k : '',
        };
    }
    createFromIssue(issueUUID, callInfo, time) {
        return this.createFromEntry({
            level: common_1.LogLevel.Error,
            tag: 'bf_issue',
            text: issueUUID,
        }, callInfo, time);
    }
    createGapLog(logs) {
        const text = JSON.stringify({
            bf_start_date: logs[0].at,
            bf_end_date: logs[logs.length - 1].at,
        });
        return {
            x: logs[0].x,
            t: text,
            m: '',
            at: logs[0].at,
            tg: 'bf_gap_log',
            f: '',
            l: 0,
            ll: 0,
            u: logs[0].u,
        };
    }
}
exports.LogFactory = LogFactory;
