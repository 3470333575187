"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.OperationModes = exports.OperationMode = void 0;
var OperationMode;
(function (OperationMode) {
    OperationMode["A"] = "A";
    OperationMode["B"] = "B";
    OperationMode["C"] = "C";
})(OperationMode || (exports.OperationMode = OperationMode = {}));
class OperationModes {
    constructor(apiFeatures) {
        this.apiFeatures = apiFeatures;
    }
    hasLeaderElection() {
        return this.apiFeatures.isBroadcastChannelSupported();
    }
    hasSharedDB() {
        return this.apiFeatures.isIndexedDBSupported();
    }
    hasSharedDeviceInfo() {
        return this.apiFeatures.isLocalStorageSupported();
    }
    getMode() {
        return __awaiter(this, void 0, void 0, function* () {
            const [isMultiTab, isMultiThread] = yield Promise.all([
                this.isMultiTab(),
                this.isMultiThread()
            ]);
            if (isMultiTab) {
                return isMultiThread
                    ? OperationMode.A
                    : OperationMode.B;
            }
            else {
                return OperationMode.C;
            }
        });
    }
    isMultiTab() {
        return __awaiter(this, void 0, void 0, function* () {
            return (yield this.hasLeaderElection()) && (yield this.hasSharedDB()) && this.hasSharedDeviceInfo();
        });
    }
    isMultiThread() {
        return __awaiter(this, void 0, void 0, function* () {
            return (yield this.hasSharedDB()) && this.apiFeatures.isWorkerSupported();
        });
    }
}
exports.OperationModes = OperationModes;
