"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.truncate = exports.CacheDecoratorFactory = exports.createDeferred = void 0;
function createDeferred() {
    const deferred = {
        isFulfilled: false,
        isRejected: false,
        isResolved: false,
    };
    deferred.promise = new Promise((resolve, reject) => {
        deferred.resolve = (value) => {
            const resolved = deferred;
            resolved.isFulfilled = true;
            resolved.isRejected = false;
            resolved.isResolved = true;
            resolved.value = value;
            resolve(value);
        };
        deferred.reject = (reason) => {
            const rejected = deferred;
            rejected.isFulfilled = true;
            rejected.isRejected = true;
            rejected.isResolved = false;
            rejected.err = reason;
            reject(reason);
        };
    });
    return deferred;
}
exports.createDeferred = createDeferred;
function CacheDecoratorFactory(cache) {
    return function CacheDecorator() {
        return function (_, propertyKey, descriptor) {
            const method = descriptor.value;
            descriptor.value = function () {
                if (!cache.has(propertyKey)) {
                    cache.set(propertyKey, method.apply(this));
                }
                return cache.get(propertyKey);
            };
        };
    };
}
exports.CacheDecoratorFactory = CacheDecoratorFactory;
function truncate(value, maxBytes) {
    const potentialValueSizeBytes = value.length * 3;
    if (potentialValueSizeBytes <= maxBytes) {
        return Promise.resolve(value);
    }
    else {
        const blob = new Blob([value]);
        const valueSizeBytes = blob.size;
        if (valueSizeBytes <= maxBytes) {
            return Promise.resolve(value);
        }
        else {
            return blob.slice(0, maxBytes).text();
        }
    }
}
exports.truncate = truncate;
