"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CommandType = void 0;
var CommandType;
(function (CommandType) {
    CommandType["AddIssue"] = "add-issue";
    CommandType["AddLogEntry"] = "add-log-entry";
    CommandType["AddLogMessage"] = "add-log-message";
    CommandType["ForceSendOnce"] = "force-send-once";
    CommandType["SetDeviceKey"] = "set-device-key";
})(CommandType || (exports.CommandType = CommandType = {}));
