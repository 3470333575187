"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.InMemoryLogsTable = exports.IDBLogsTable = void 0;
class IDBLogsTable {
    constructor(table, logFactory) {
        this.table = table;
        this.logFactory = logFactory;
    }
    add(item) {
        return this.table.add(item);
    }
    count(sessionUUID) {
        return this.table.countBy('sessionUUID', sessionUUID);
    }
    deleteBatch(sessionUUID, batchSize) {
        return this.table.deleteBatch(0, batchSize, ['sessionUUID', sessionUUID]);
    }
    deleteOldLogs(sessionUUID_1) {
        return __awaiter(this, arguments, void 0, function* (sessionUUID, batchSize = 10) {
            const logs = yield this.table.getBatch(sessionUUID, batchSize);
            if (logs.length <= 1) {
                return;
            }
            const log = {
                sessionUUID,
                data: this.logFactory.createGapLog(logs.map(e => e.data)),
            };
            yield Promise.all([
                this.table.patchByIdx(logs[0].id, log),
                this.table.deleteBatch(1, batchSize - 1, ['sessionUUID', sessionUUID]),
            ]);
        });
    }
    getBatch(sessionUUID, batchSize) {
        return this.table.getBatch(sessionUUID, batchSize);
    }
}
exports.IDBLogsTable = IDBLogsTable;
class InMemoryLogsTable {
    constructor(logFactory, storageLimit) {
        this.logFactory = logFactory;
        this.storageLimit = storageLimit;
        this.data = [];
        this.idCounter = 0;
    }
    getNextID() {
        return this.idCounter++;
    }
    add(item) {
        return __awaiter(this, void 0, void 0, function* () {
            if (this.data.length >= this.storageLimit) {
                const error = new Error('InMemory storage quota exceeded');
                error.name = 'QuotaExceededError';
                throw error;
            }
            const id = this.getNextID();
            this.data.push(Object.assign(Object.assign({}, item), { id }));
        });
    }
    count(sessionUUID) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.data.filter(d => d.sessionUUID === sessionUUID).length;
        });
    }
    deleteBatch(sessionUUID, batchSize) {
        return __awaiter(this, void 0, void 0, function* () {
            const data = [];
            let counter = 0;
            for (let i = 0; i < this.data.length; i++) {
                const log = this.data[i];
                if (log.sessionUUID === sessionUUID && counter < batchSize) {
                    counter++;
                }
                else {
                    data.push(log);
                }
            }
            this.data = data;
        });
    }
    deleteOldLogs(_sessionUUID) {
        return __awaiter(this, void 0, void 0, function* () {
            const logs = this.data.slice(0, 10);
            if (logs.length <= 1) {
                return;
            }
            this.data[0].data = this.logFactory.createGapLog(logs.map(e => e.data));
            this.data.splice(1, 9);
        });
    }
    getBatch(sessionUUID, batchSize) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.data.filter(d => d.sessionUUID === sessionUUID).slice(0, batchSize);
        });
    }
}
exports.InMemoryLogsTable = InMemoryLogsTable;
